import { appMode } from '@/config';
import * as OM from '@/model'
import { createStore, StoreOptions } from 'vuex';


let storeState = {
    isWeb: !!!window.cordova,
    isOnline: <boolean> true,
    beforeNavCbs: [],
    afterNavCbs: [],
    globalSpinner: 0,
    isTestMode: () => {
        return appMode == 'local' || appMode == 'test';
    },
}

let vuestore: StoreOptions<typeof storeState> = {
    state: storeState,
}
let store = createStore(vuestore);

export default store;
