import { CommonServices } from "@/services/CommonServices";
import * as OM from '@/model'
import store from "@/store";
import { StorageServices } from "./StorageServices";
import router from "@/router";

class _LoginServices {

    loginCallback(token: string){
        
        return new Promise<void>((resolve, reject) => {
            CommonServices.setAuthToken(token);
            resolve();
        }) 
    }

    logout(){
        let prom = new Promise<void>((resolve, reject) => {
            CommonServices.destroyToken();
            resolve();
        })
        return prom;
    }
}

export let LoginServices = new _LoginServices();
