<template>
	<div class="bgImage left">
		<img class="img1" src="@/img/topleftbg.png" /><br/>
		<img class="img2" src="@/img/bottomleftbg.png" />
	</div>
	<div class="bgImage topRight">
		<img src="@/img/toprightbg.png" />
	</div>
	<div class="bgImage bottomRight">
		<img src="@/img/bottomrightbg.png" />
	</div>
	<router-view v-slot="{ Component }">
		<div class="appPageContainer">
			<div class="appMode" v-if="appMode != 'production'">Modalità: {{appMode}}</div>
			<component :is="Component" />
		</div>
	</router-view>
	<div class="globalSpinner" v-if="$store.state.globalSpinner > 0">
		<mini-spinner></mini-spinner>
	</div>
	<modals-container></modals-container>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ModalServices } from '@/services/ModalServices';
import { appMode } from './config';
import store from './store';
import { CommonServices } from './services/CommonServices';
import { LoginClient } from './services/Services';
import { LoginServices } from './services/LoginServices';
import AlertModal from './components/modals/alertModal.vue';

@Options({})
export default class App extends Vue {
	
	appMode: string = appMode;
	
	mounted(){
		document.addEventListener("deviceready", () => {

			document.addEventListener("offline", () => {
				store.state.isOnline = false;
			}, false);
			document.addEventListener("online", () => {
				store.state.isOnline = true;
			}, false);

			
			document.addEventListener("backbutton", () => {
				if(this.$opModal.modals().length > 0){
					this.$opModal.closeLast();
					return;
				}
				
				if(this.$route.meta.closeAppModal){
					ModalServices.confirmModal('', 'Vuoi chiudere l\'app?', "Si", "No",
					() => { 
						(<any>window.navigator).app.exitApp(); 
					})
				} else {
					this.$router.back();
				}
			}, false);
				
			window.addEventListener('keyboardWillShow', (event: any) => {
				document.body.classList.add('keyboardopen');
				// if(device.platform == "Android"){
				//     let root = document.documentElement;
				//     root.style.setProperty('--keyboardHeight', (event).keyboardHeight +  "px");
				// }
			});

			window.addEventListener('keyboardWillHide', (event) => {
				document.body.classList.remove('keyboardopen');
				let pageContainer = document.querySelector('.appPageContainer')
				if(pageContainer.scrollHeight <= window.innerHeight){
					pageContainer.scrollTop = 0;
				}
				
				// if(device.platform == "Android"){
				//     let root = document.documentElement;
				//     root.style.setProperty('--keyboardHeight', "0px");
				// }
			});

		}, false);

		CommonServices.unauthorizedCb = () => {
			this.requestPassword();
		}
			
		ModalServices.setOpModal(this.$opModal);
	}

	requestPassword(){
		ModalServices.inputModal("Non autorizzato", "Inserisci la password per continuare", "", "", "", "",
		(input) => {
			LoginClient.login(input)
			.then(x => {
				LoginServices.loginCallback(x);
				window.location.reload();
			})
			.catch(err => {
				this.$opModal.show(AlertModal, {
					text: "Password errata",
				}, () => {
					this.requestPassword();
				});
			})
		}, 
		() => {

		});
	}
	
}
</script>