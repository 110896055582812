<template>
<div class="opModal confirmModal">
    <div class="header" v-if="title">
        <span v-html="title"></span>
    </div>
    <div class="body">
        <span v-html="text"></span>
        <div class="mt-3 mw-700">
            <div>
                <button type="button" class="btn btn-primary" @click="ok">
                    <span v-html="okText"></span>
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-hollow mt-2" @click="ko">
                    <span v-html="koText"></span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class ConfirmModal extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop({
        default: 'OK'
    }) okText: string;
    @Prop({
        default: 'Annulla'
    }) koText: string;
    @Prop() okCallback: () => Promise<any>;
    @Prop() koCallback: () => Promise<any>;

    async ok(){
        if(this.okCallback){
            await this.okCallback();
        }
        this.$emit('close');
    }

    async ko(){
        if(this.koCallback){
            await this.koCallback();
        }
        this.$emit('close');
    }

}
</script>
<style scoped>

</style>