import * as OM from '@/model';
import {v4 as uuidv4} from 'uuid';

class _StorageServices  {
    
    private setInLocalStorage<T>(lsName: string, value: T) {
        window.localStorage.setItem(lsName, JSON.stringify(value));
    }
    
    private readFromLocalStorage<T>(lsName: string): T | null {
        let data = window.localStorage.getItem(lsName);
        if(data){
            return JSON.parse(data);
        } else {
            return null;
        }
    }
    
    setOpTableFilters(key: string, filters: any){
        this.setInLocalStorage('_op_' + key, filters);
    }
    getOpTableFilters(key: string){
        return this.readFromLocalStorage<any>('_op_' + key);
    }
    
    SaveLead(item: OM.Lead){
        let leadList = this.readFromLocalStorage<OM.Lead[]>('leadList') || [];
        if(!item.identifier){
            item.identifier = uuidv4();
            item.createdOn = new Date().toISOString();
            leadList.unshift(item);
        } else {
            let indexOf = leadList.findIndex(x => x.identifier == item.identifier);
            leadList[indexOf] = item;
        }
        this.setInLocalStorage('leadList', leadList);
    }
    
    SaveAllLeads(list: OM.Lead[]){
        this.setInLocalStorage('leadList', list);
    }
    
    GetAllLeads(){
        let leadList = this.readFromLocalStorage<OM.Lead[]>('leadList');
        return leadList;
    }
    
}

export let StorageServices = new _StorageServices();