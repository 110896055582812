import Vue from 'vue';
import moment from 'moment';
import * as OM from '@/model';

export default {
    date(value: any, _format: string = 'DD/MM/YYYY', _showTime: boolean = false) {
        if (value) {
            let format;
            if(!_format){
                format = 'DD/MM/YYYY';
            } else {
                format = _format;
            }
            if(_showTime)
                format += ' HH:mm:ss';
            return moment(value).locale('it').format(format);
        }
    },

    int(value: any) {
        if (value || value == 0) {
            return Math.round(value);
        }
    },

    currency(value: any, decimals: number = 2) {
        if (value || value == 0) {
            let val = (value/1).toFixed(decimals).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        }
    },

    perc(value: any, decimals: number) {
        if(!decimals)
            decimals = 0;
        if (value || value == 0) {
            return (value * 100).toFixed(decimals);
        }
    },

    daysPeriod(value: any) {
        if (!value)
            return value;
        
        if(value < 365)
            return value;
        else if(value < 1080)
            return value;
        else
            return parseFloat((value / 365).toFixed(1));
    },
}
