<template>
<div class="opModal alertModal">
    <div class="header" v-if="title">
        <span v-html="title"></span>
    </div>
    <div class="body">
        <span v-html="text"></span>
        <div class="mt-3 mw-700">
            <button type="button" class="btn btn-primary" @click="ok" ref="okButton">
                <span v-html="okText"></span>
            </button>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class AlertModal extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop({
        default: 'OK'
    }) okText: string;
    @Prop() okCallback: () => Promise<any>;

    async ok(){
        if(this.okCallback){
            await this.okCallback();
        }
        this.$emit('close');
    }

    mounted(){
        (<HTMLButtonElement>this.$refs.okButton).focus();
    }

}
</script>
<style scoped>

</style>