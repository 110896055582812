<template>
<div class="opModal inputModal">
    <div class="header" v-if="title">
        <span v-html="title"></span>
    </div>
    <div class="body">
        <span v-html="text"></span>
        <div class="mt-2">
            <input type="text" class="form-control" :placeholder="placeholder" v-model="input" />
        </div>
        <div class="mt-5">
            <div>
                <button type="button" class="btn btn-block btn-primary" @click="ok">
                    <span v-html="okText"></span>
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-block btn-link mt-2" @click="ko">
                    <span v-html="koText"></span>
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Options({})
export default class ConfirmModal extends Vue {

    @Prop() title: string;
    @Prop() text: string;
    @Prop({
        default: 'OK'
    }) okText: string;
    @Prop({
        default: 'Annulla'
    }) koText: string;
    @Prop({
        default: 'Scrivi qui'
    }) placeholder: string;
    @Prop() value: string;
    @Prop() okCallback: (input: string) => Promise<any>;
    @Prop() koCallback: () => Promise<any>;

    input: string = "";

    created(){
        if(this.value){
            this.input = this.value;
        }
    }

    async ok(){
        if(this.okCallback){
            await this.okCallback(this.input);
        }
        this.$emit('close');
    }

    async ko(){
        if(this.koCallback){
            await this.koCallback();
        }
        this.$emit('close');
    }

}
</script>
<style scoped>

</style>