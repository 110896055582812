import { createRouter, createWebHashHistory, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'form ',
        component: () => import('@/views/form.vue')
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('@/views/table.vue'),
    },
    {
        path: '/privacy_artena',
        name: 'privacy_artena',
        component: () => import('@/views/privacy_artena.vue'),
    },
    {
        path: '/privacy_omoda',
        name: 'privacy_omoda',
        component: () => import('@/views/privacy_omoda.vue'),
    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
